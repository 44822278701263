//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './StatisticsGrossProfitExchangeIncomeFilters.vue';

import tableDateConfig from './tableDateConfig';
import tableTotalConfig from './tableTotalConfig';

export default {
  components: { Filters },

  data() {
    return {
      tableDateConfig,
      tableTotalConfig,
      loader: false,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('currencies', ['currencies']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapGetters('currencies', ['toCurrencyFormat']),

    ...mapState('statisticsGrossProfitExchangeIncome', [
      'page',
      'pages',
      'data',
      'additionalData',
      'grossProfitFull',
      'sort',
    ]),

    grossProfitFullList() {
      const {
        cumulativeCurrencyId,
        p2P,
        trades,
        transactions,
        merchant,
      } = this.grossProfitFull;
      if (typeof cumulativeCurrencyId !== 'number') return [];
      return [
        {
          id: 'GROSS_PROFIT_TYPE-WD',
          title: this.$t('Deposit/withdrawal'),
          value: this.toCurrencyFormat(transactions, cumulativeCurrencyId),
        },
        {
          id: 'GROSS_PROFIT_TYPE-P2P',
          title: this.$t('P2P'),
          value: this.toCurrencyFormat(p2P, cumulativeCurrencyId),
        },
        {
          id: 'GROSS_PROFIT_TYPE-TRADES',
          title: this.$t('Trades'),
          value: this.toCurrencyFormat(trades, cumulativeCurrencyId),
        },
        {
          id: 'GROSS_PROFIT_TYPE-MERCHANT',
          title: this.$t('Merchant'),
          value: this.toCurrencyFormat(merchant, cumulativeCurrencyId),
        },
        {
          id: 'GROSS_PROFIT_TYPE-TOTAL',
          title: this.$t('Total'),
          value: this.toCurrencyFormat((transactions + p2P + trades + merchant), cumulativeCurrencyId),
        },
      ].filter((e) => !process.env.VUE_APP_EXCLUDE_CONST?.split(' ').includes(e.id));
    },

    preparedData() {
      const { data, additionalData } = this;
      const preparedData = [];
      if (!additionalData.currencies) return preparedData;
      // generate total row
      const totalRow = {};
      totalRow.date = `${this.$utils.getDate(additionalData.dateFrom)} – ${this.$utils.getDate(additionalData.dateTo)}`;
      totalRow.cumulativeProfit = additionalData.cumulativeProfit;
      additionalData.currencies.forEach((c) => {
        totalRow[c.currencyTitle] = {
          cumulativeProfit: c.cumulativeProfit,
          profit: c.profit,
        };
      });
      preparedData.push(totalRow);
      // generate other rows
      data.forEach((e) => {
        const row = {};
        row.date = this.$utils.getDate(e.date);
        row.cumulativeProfit = e.cumulativeProfit;
        e.currencies.forEach((c) => {
          row[c.currencyTitle] = {
            cumulativeProfit: c.cumulativeProfit,
            profit: c.profit,
          };
        });
        preparedData.push(row);
      });

      return preparedData;
    },

    tableConfig() {
      const { currencies } = this;
      if (currencies.length === 0) return [];

      return currencies.map((e) => {
        return {
          width: 100,
          header: {
            type: 'text',
            caption: e.title,
          },
          cell: {
            type: 'slot',
            name: e.title,
          },
        };
      });
    },

    tableWidth() {
      const { currencies } = this;
      return currencies.length * 200;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('statisticsGrossProfitExchangeIncome', {
      loadDataAction: 'loadData',
      loadGrossProfitFullAction: 'loadGrossProfitFull',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await Promise.allSettled([
        this.loadDataAction(data),
        this.loadGrossProfitFullAction(),
      ]);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },
  },
};
