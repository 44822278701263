//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import StatisticsGrossProfitExchangeIncome from '~/views/StatisticsGrossProfit/StatisticsGrossProfitExchangeIncome/StatisticsGrossProfitExchangeIncome.vue';

export default {
  components: {
    StatisticsGrossProfitExchangeIncome,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      // this.loadGrossProfit(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('statisticsGrossProfitExchangeIncome', {
      loadGrossProfit: 'loadData',
    }),
  },
};
