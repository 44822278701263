export default [
  {
    width: 100,
    header: {
      type: 'text',
      caption: 'Total profit',
    },
    cell: {
      type: 'slot',
      name: 'totalProfit',
    },
  },
];
